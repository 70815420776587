import React, { useContext, useCallback, useState } from "react"
import { Link } from "@reach/router"
import { graphql, navigate } from "gatsby"
import {
  Header,
  Footer,
  SEO,
  HeaderCar,
  VideoBackground,
  ColorCar,
  GalleryInterior,
  Motor,
  GalleryExterior,
  PracticalData,
  Recommends,
  TechnicalData,
  DownloadSheet,
  ActionButton,
  Accessories,
  Insurance,
} from "../components"
import { CarContext } from "../context"
import {
  getMinimunPrices,
  urlWithSession,
  getModelShortNameOrDefault,
  getModelAccessories,
} from "../utils"
import { useSpecialDevice } from "../hooks"

export default function ModelTemplate({ data, pageContext: { modelId } }) {
  const [model] = useState(
    data.allModels.nodes.find(model => model.ID === modelId)
  )
  const [accessories] = useState(
    getModelAccessories(model, data.allAccessories.nodes)
  )
  const appContext = useContext(CarContext)
  const {
    comparatorSelections,
    setComparatorSelections,
    selectedVersion,
    setSelectedVersion,
    modelsPricesStocks,
    windowWidth,
  } = appContext

  // CALCULATE CORRECT MINIMUN PRICES IF THEY ARE WRONG OR INEXISTENT
  const minimunPrices = getMinimunPrices(
    modelsPricesStocks,
    model?.versions.map(v => v.id),
    { allowOutOfStock: true }
  )
  if (minimunPrices?.versionId !== selectedVersion?.versionId) {
    setSelectedVersion(minimunPrices)
  }

  // Determine if this model is out of stock (no versions available)
  // const outOfStock = !isModelAvailable(model, modelsPricesStocks)

  const goToCompare = useCallback(() => {
    if (
      !comparatorSelections.length ||
      comparatorSelections[0].name !== model.name
    )
      setComparatorSelections([model])
    navigate(urlWithSession(`/comparador`))
  }, [comparatorSelections, setComparatorSelections, model])

  const GenerateActionButton = useCallback(
    (text, className = "i-want-it-button") => (
      <Link to={urlWithSession(`/config/${model.slug}`)}>
        <ActionButton className={className} type="primary" text={text} />
      </Link>
    ),
    [model.slug]
  )

  return (
    <>
      <SEO
        title={`${model.name} ${model.slogan} | DercoCenter X`}
        url={`https://dercocenterx.cl/model/${model.slug}`}
        description={`${model.name} ${model.slogan}, con los mejores precios y opciones de financiamiento. Puedes entregar tu auto en parte de pago, reservar en línea y recibir en tu domicilio.`}
      ></SEO>
      <Header />
      <div className="car-model">
        <HeaderCar
          page="model"
          model={model}
          context={{
            comparatorSelections,
            setComparatorSelections,
          }}
        />
        <VideoBackground model={model} />
        <ColorCar
          model={model}
          appContext={appContext}
          setMoreInfo={() => appContext.setSelectedVersion(minimunPrices)}
        />
        {GenerateActionButton(`ARMA TU ${getModelShortNameOrDefault(model)}`)}
        <GalleryInterior model={model} />
        <Motor model={model} isMobile={windowWidth < 610} />
        {GenerateActionButton(
          `PERSONALIZA TU ${getModelShortNameOrDefault(model)}`
        )}
        <GalleryExterior model={model} />
        {!model.partialUpload && (
          <ActionButton
            className="compare-models-button"
            type="secondary"
            text="COMPARA LOS MODELOS"
            onClick={goToCompare}
          />
        )}
        {model.practicalData && (
          <PracticalData
            data={{
              model,
              comparatorSelections,
              setComparatorSelections,
            }}
          />
        )}
        <Recommends model={model} />
        {GenerateActionButton(
          `CONFIGURA TU ${getModelShortNameOrDefault(model)}`
        )}
        {model.technicalData && (
          <TechnicalData
            data={{
              model,
              comparatorSelections,
              setComparatorSelections,
            }}
          />
        )}
        {GenerateActionButton(
          `ARMA TU ${getModelShortNameOrDefault(model)}`,
          "final-i-want-it-button"
        )}
        <DownloadSheet model={model} />
        <Accessories accessories={accessories} />
        <Insurance />
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
  query FULL_QUERY_5 {
    allModels {
      nodes {
        ...ModelBasic
        ...Highlights
        ...Gallery
        ...HeroVideo
        ...RecommendVideo
        ...ColorGallery
        ...ModelStrings
        ...EngineSpecs
        ...DimensionSpecs
        ...PracticalData
        ...TechnicalData
        ...Versions
        ...ConfigOptions
      }
    }
    allAccessories {
      nodes {
        ...AccessoriesBasic
      }
    }
  }
`
